import React, { useState, useEffect } from 'react';
import { Box, Heading, Avatar, Text, Button, Flex, Tag } from '@chakra-ui/react';
import { FaUserSlash, FaBan, FaEnvelope, FaGavel } from 'react-icons/fa';
import axios from 'axios';

const VueModerateur = ({ userId }) => {
    const [userInfo, setUserInfo] = useState(null);

    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                const response = await axios.get(`https://adpanel.bot.mrapi.net/api/user-info/208604091335704576`);
                setUserInfo(response.data);
            } catch (error) {
                console.error("Failed to fetch user info:", error);
            }
        };

        fetchUserInfo();
    }, [userId]);

    if (!userInfo) {
        return <Text>Loading...</Text>;
    }

    return (
        <Box
            borderWidth="1px"
            borderRadius="lg"
            p={4}
            bg="gray.800"
            color="white"
            mt="20px" // Ajustez cette valeur en fonction de la hauteur de votre navbar
            position="relative"
            zIndex="10"
            maxW="600px"
            mx="auto" // Centrer horizontalement
        >
            {/* Header avec avatar et nom d'utilisateur */}
            <Flex alignItems="center" mb={4}>
                <Avatar src={userInfo.avatar} size="lg" mr={4} />
                <Box>
                    <Heading size="lg">{userInfo.nickname || userInfo.username}</Heading>
                    <Text fontSize="sm" color="gray.400">{userInfo.status}</Text>
                </Box>
            </Flex>

            {/* Actions modératrices */}
            <Flex justify="space-around" mb={4}>
                <Button leftIcon={<FaEnvelope />} colorScheme="blue">Envoyer un MP</Button>
                <Button leftIcon={<FaUserSlash />} colorScheme="orange">Expulser</Button>
                <Button leftIcon={<FaBan />} colorScheme="red">Bannir</Button>
                <Button leftIcon={<FaGavel />} colorScheme="purple">Mesure temporaire</Button>
            </Flex>

            {/* Vue du modérateur */}
            <Box mb={4}>
                <Heading size="md" mb={2}>Vue du modérateur</Heading>
                <Text>Messages: {userInfo.messages}</Text>
                <Text>Liens: {userInfo.links}</Text>
                <Text>Contenu multimédia: {userInfo.multimedia}</Text>
            </Box>

            {/* Permissions modératrices */}
            <Box mb={4}>
                <Heading size="md" mb={2}>Permissions</Heading>
                <Flex wrap="wrap" gap={2}>
                    {userInfo.permissions.map((perm, index) => (
                        <Tag key={index} colorScheme="purple">{perm}</Tag>
                    ))}
                </Flex>
            </Box>

            {/* Rôles de l'utilisateur */}
            <Box>
                <Heading size="md" mb={2}>Rôles</Heading>
                <Flex wrap="wrap" gap={2}>
                    {userInfo.roles.map((role, index) => (
                        <Tag key={index} bg={role.color}>{role.name}</Tag>
                    ))}
                </Flex>
            </Box>
        </Box>
    );
};

export default VueModerateur;
