import React, { useState, useEffect } from 'react';
import { Box, Text, VStack, HStack, Avatar } from '@chakra-ui/react';
import axios from 'axios';

const DraggableUserCard = () => {
  const [channels, setChannels] = useState([]); // État pour les canaux vocaux avec utilisateurs
  const [position, setPosition] = useState({ x: 20, y: 340 });
  const [isDragging, setIsDragging] = useState(false);
  const [offset, setOffset] = useState({ x: 0, y: 0 });

  // Fonction pour récupérer les canaux vocaux avec utilisateurs connectés
  const fetchConnectedChannels = async () => {
    try {
      const response = await axios.get('https://adpanel.bot.mrapi.net/api/connected-users');
      setChannels(response.data); // Met à jour la liste des canaux avec utilisateurs
    } catch (error) {
      console.error('Erreur lors de la récupération des canaux vocaux connectés :', error);
    }
  };

  useEffect(() => {
    fetchConnectedChannels();
    const interval = setInterval(fetchConnectedChannels, 5000); // Met à jour toutes les 5 secondes
    return () => clearInterval(interval);
  }, []);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setOffset({
      x: e.clientX - position.x,
      y: e.clientY - position.y,
    });
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      setPosition({
        x: e.clientX - offset.x,
        y: e.clientY - offset.y,
      });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging, offset]);

  return (
    <Box
      position="absolute"
      top={`${position.y}px`}
      left={`${position.x}px`}
      cursor="grab"
      onMouseDown={handleMouseDown}
      zIndex="1000"
      bg="transparent"
      color="white"
      padding="4"
      borderRadius="md"
      boxShadow="lg"
      width="300px"
      border="1px solid"
      borderColor="purple.300"
    >
      {channels && channels.length > 0 ? (
        channels.map((channel, index) => (
          <Box key={index} mb="4">
            <Text fontSize="lg" fontWeight="bold" mb="2" align="center">
              - {channel.channelName} -
            </Text>
            <VStack align="start" spacing="2" align="center">
              {channel.users.map((user) => (
                <HStack key={user.id} spacing="3" align="center">
                  <Avatar name={user.name} src={user.avatar} size="sm" />
                  <Text fontSize="md">{user.name}</Text>
                </HStack>
              ))}
            </VStack>
          </Box>
        ))
      ) : (
        <Text fontSize="sm" color="gray.400" align="center">
          Pérsonne n'est connecté..
        </Text>
      )}
    </Box>
  );
};

export default DraggableUserCard;
