import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Button, Table, Tbody, Td, Th, Thead, Tr, Flex, Badge } from '@chakra-ui/react';

const AdminReports = () => {
    const [reports, setReports] = useState([]);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        fetchReports();

    }, []);

    // Fetch the reports from the API
    const fetchReports = async () => {
        setLoading(true);
        try {
            const { data } = await axios.get('https://peaky.api.mrapi.net/api/reports');
            setReports(data);
        } catch (error) {
            console.error('Error fetching reports:', error);
        } finally {
            setLoading(false);
        }
    };

    // Send updates to the WebSocket server
    const sendUpdateToWebSocket = (reportId, action) => {
        if (socket && socket.readyState === WebSocket.OPEN) {
            const message = JSON.stringify({ reportId, status: action });
            socket.send(message);
            console.log('Message sent to WebSocket:', message);
        } else {
            console.error('WebSocket is not open.');
        }
    };

    // Handle actions (work, validate, close, delete) and send via WebSocket
    const handleAction = async (reportId, action) => {
        try {
            // Ensure that reportId is being correctly passed here
            await axios.post(`https://peaky.api.mrapi.net/api/reports/${reportId}/${action}`);
            fetchReports(); // Refresh reports after performing an action

            // Send the action to the WebSocket server
            sendUpdateToWebSocket(reportId, action);
        } catch (error) {
            console.error(`Error performing ${action} on report:`, error);
        }
    };

    const getStatusColorScheme = (status) => {
        switch (status) {
            case 'open':
                return 'yellow';
            case 'in_progress':
                return 'blue';
            case 'resolved':
                return 'green';
            case 'rejected':
                return 'red';
            case 'closed':
                return 'red';
            case 'archived':
                return 'red';
            case 'public_channel':
                return 'purple';
            default:
                return 'gray';
        }
    };

    return (
        <Flex direction="row" h="calc(90vh - 80px)" p={4} mt="60px" bg="transparent" borderRadius="md" overflow="hidden">
        <Box p={5}>
            <Flex justify="space-between" mb={4}>
                <Button colorScheme="teal" onClick={fetchReports} isLoading={loading}>
                    Refresh Reports
                </Button>
            </Flex>
            <Table variant="simple">
                <Thead>
                    <Tr>
                        <Th>ID</Th>
                        <Th>User</Th>
                        <Th>Status</Th>
                        <Th>Message</Th>
                        <Th>Actions</Th>
                    </Tr>
                </Thead>
                <Tbody>
                {reports.length > 0 && reports.map((report) => {
                    if (report.id) {  // Ensure report.id is defined
                        return (
                            <Tr key={report.id}>
                                <Td>{report.id}</Td>
                                <Td>{report.user_id}</Td>
                                <Td>
                                    <Badge colorScheme={getStatusColorScheme(report.status)}>
                                        {report.status.replace('_', ' ')}
                                    </Badge>
                                </Td>
                                <Td>{report.report_message}</Td>
                                <Td>
                                    <Button
                                        size="sm"
                                        colorScheme="blue"
                                        onClick={() => handleAction(report.id, 'in_progress')}
                                    >
                                        Work
                                    </Button>
                                    <Button
                                        size="sm"
                                        colorScheme="green"
                                        onClick={() => handleAction(report.id, 'validate')}
                                    >
                                        Validate
                                    </Button>
                                    <Button
                                        size="sm"
                                        colorScheme="red"
                                        ml={2}
                                        onClick={() => handleAction(report.id, 'rejected')}
                                    >
                                        Close
                                    </Button>
                                    <Button
                                        size="sm"
                                        colorScheme="purple"
                                        ml={2}
                                        onClick={() => handleAction(report.id, 'delete')}
                                    >
                                        Delete
                                    </Button>
                                </Td>
                            </Tr>
                        );
                    } else {
                        console.warn("Report ID missing");
                        return null;
                    }
                })}

                </Tbody>
            </Table>
        </Box>
        </Flex>
    );
};

export default AdminReports;
