// routes.js
import Dashboard1 from "views/Dashboard/Dashboard/index2.js";
import Profile from "views/Dashboard/Profile";
import SignIn from "views/Auth/SignIn.js";
import SignUp from "views/Auth/SignUp.js";

//Custom
import UsersList from "views/Admin/Users/list";
import EditUser from "views/Admin/Users/edit";
import CreateUser from "views/Admin/Users/create";

import {

  PersonIcon,
  RocketIcon,
} from "components/Icons/Icons";
import { FaDiscord } from "react-icons/fa";
import { FaRegUser } from "react-icons/fa";
import { MdOutlineDashboard } from "react-icons/md";
import { MdOutlineInventory2 } from "react-icons/md";
import { LuFileCode2 } from "react-icons/lu";
import { BsTerminal } from "react-icons/bs";
import { FaDocker } from "react-icons/fa6";
import { RiSlashCommands2 } from "react-icons/ri";
import { MdOutlineRemoveModerator } from "react-icons/md";
import { GiRadioTower } from "react-icons/gi";
import { TbMessageReport } from "react-icons/tb";
import { VscDebugConsole } from "react-icons/vsc";

import DiscordApp from "views/Admin/Discord";
import DMList from "views/Admin/Discord/DmMessage";
import BotLogsConsole from "views/Admin/Discord/Logs/logs";
import VoiceBotComponent from "views/Admin/Discord/Voices/connect";
import VoiceChannelList from "views/Admin/Discord/Voices/list";
import AdminReports from "views/Admin/Discord/Logs/report";
import DiscordAdminADP from "views/Admin/Discord/adp";
import BotLogsConsoleADP from "views/Admin/Discord/Logs/logsADP";
import BotLogsConsoleMRU from "views/Admin/Discord/Logs/logsMRU";
import RadioTrackInfo from "views/Dashboard/Radios/TrackingQDANCE";
import CodeEditor from "views/Dashboard/Editors/edit";
import TextChannelList from "views/Admin/Discord/Messenger/TextChannel";
import CustomCommands from "views/Admin/Systeme/CustomCommands";
import DockerComponent from "views/Admin/Systeme/Docker";
import ContainerList0 from "views/Admin/Systeme/Docker/containerslist";
import Discord2 from "views/Dashboard/Dashboard/Discord2";
import VueModerateur from "views/Admin/Discord/Moderation/moderateur";
import WSSTerminal from "views/Admin/Terminal/Terminal";
import KillSwitch from "views/Admin/Systeme/KillSwitch/killswitch";


// Define routes with requiredGrades and hidden properties
const dashRoutes = [
  {
    path: "/!/Secure/-/0/MariaBird/Services/KillSwitch",
    name: "Kill Switch",
    icon: <BsTerminal color="inherit" />,
    component: KillSwitch,
    layout: "/admin",
    requiredGrades: [100, 255],
    hidden: true,
  },
  {
    path: "/!/Secure/-/Terminal/MariaBird",
    name: "Terminal",
    icon: <BsTerminal color="inherit" />,
    component: WSSTerminal,
    layout: "/admin",
    requiredGrades: [100, 255],
    hidden: false,
  },
  {
    path: "/!/Moderateur",
    name: "Modo",
    icon: <MdOutlineRemoveModerator color="inherit" />,
    component: VueModerateur,
    layout: "/admin",
    requiredGrades: [100, 255],
    hidden: true,
  },
  {
    path: "/!/Docker",
    name: "Docker",
    icon: <FaDocker color="inherit" />,
    component: DockerComponent,
    layout: "/admin",
    requiredGrades: [100, 255],
    hidden: false,
  },
  {
    path: "/!/Docker/e/Containers",
    name: "Docker",
    icon: <LuFileCode2 color="inherit" />,
    component: ContainerList0,
    layout: "/admin",
    requiredGrades: [100, 255],
    hidden: true,
  },
  {
    path: "/!/CustomCommands",
    name: "Commands",
    icon: <RiSlashCommands2 color="inherit" />,
    component: CustomCommands,
    layout: "/admin",
    requiredGrades: [100, 255],
    hidden: false,
  },
  {
    path: "/!/vscode/edit",
    name: "Code Editor",
    icon: <LuFileCode2 color="inherit" />,
    component: CodeEditor,
    layout: "/admin",
    requiredGrades: [100, 255],
    hidden: false,
  },
  {
    path: "/!/TrackingRadio/-/onair",
    name: "OnAir Radio",
    icon: <GiRadioTower color="inherit" />,
    component: RadioTrackInfo,
    layout: "/admin",
    requiredGrades: [100, 255],
    hidden: false,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: <MdOutlineDashboard color="inherit" />,
    component: Dashboard1,
    layout: "/admin",
    requiredGrades: [10, 49, 50, 100, 255],
    hidden: false,
  },
  {
    path: "/!/Application/-/0/-/1274171415754444830/vocal",
    name: "Vocal Discord",
    icon: <FaDiscord color="inherit" />,
    component: VoiceChannelList,
    layout: "/admin",
    requiredGrades: [100, 255],
    hidden: false,
  },
  {
    path: "/!/Application/-/0/-/1274171415754444830/text",
    name: "Text Discord",
    icon: <FaDiscord color="inherit" />,
    component: TextChannelList,
    layout: "/admin",
    requiredGrades: [100, 255],
    hidden: false,
  },
  {
    path: "/!/Application/-/255/-/report",
    name: "Report/FeedBack",
    icon: <TbMessageReport color="inherit" />,
    component: AdminReports,
    layout: "/admin",
    requiredGrades: [100, 255],
    hidden: false,
  },
  {
    path: "/!/Application/-/0/-/1274171415754444830/vocal-active",
    name: "Vocal PeakyBlinders#8970",
    icon: <FaDiscord color="inherit" />,
    component: VoiceBotComponent,
    layout: "/admin",
    requiredGrades: [255],
    hidden: true,
  },
  {
    path: "/!/App/-/SECURE/WSS/-/0/-/1275695993886609501/wss",
    name: "Logs MrUtilities",
    icon: <VscDebugConsole color="inherit" />,
    component: BotLogsConsoleMRU,
    layout: "/admin",
    requiredGrades: [100, 255],
    hidden: false,
  },
  {
    path: "/!/App/-/SECURE/WSS/-/0/-/1239533031644069938/wss",
    name: "Logs ADP",
    icon: <FaDiscord color="inherit" />,
    component: BotLogsConsoleADP,
    layout: "/admin",
    requiredGrades: [100, 255],
    hidden: true,
  },
  {
    path: "/!/App/-/SECURE/WSS/-/0/-/1274171415754444830/wss",
    name: "Logs PeakyBlinders",
    icon: <FaDiscord color="inherit" />,
    component: BotLogsConsole,
    layout: "/admin",
    requiredGrades: [100, 255],
    hidden: true,
  },
  {
    path: "/!/App/-/SECURE/ADMIN/-/0/-/1239533031644069938",
    name: "Bot - ADP",
    icon: <FaDiscord color="inherit" />,
    component: DiscordAdminADP,
    layout: "/admin",
    requiredGrades: [100, 255],
    hidden: false,
  },
  {
    path: "/!/App/-/SECURE/ADMIN/-/0/-/1274171415754444830",
    name: "Bot - Peaky Blinders",
    icon: <FaDiscord color="inherit" />,
    component: DiscordApp,
    layout: "/admin",
    requiredGrades: [100, 255],
    hidden: true,
  },
  {
    path: "/!/Discord/Application/dm/list",
    name: "DiscordBot - Peaky Blinders",
    icon: <FaDiscord color="inherit" />,
    component: DMList,
    layout: "/admin",
    requiredGrades: [255],
    hidden: true,
  },
  {
    path: "/Users",
    name: "Users Administration",
    icon: <FaRegUser color="inherit" />,
    component: UsersList,
    layout: "/admin",
    requiredGrades: [100, 255],
    hidden: false,
  },
  {
    path: "/Users/Create",
    name: "Create User",
    component: CreateUser,
    layout: "/admin",
    requiredGrades: [100, 255],
    hidden: true,
  },
  {
    path: "/Users/Edit/:id",
    name: "Edit User",
    component: EditUser,
    layout: "/admin",
    requiredGrades: [100, 255],
    hidden: true,
  },
  {
    path: "/profile",
    name: "Profile",
    icon: <PersonIcon color="inherit" />,
    secondaryNavbar: true,
    component: Profile,
    layout: "/admin",
    hidden: true,
    requiredGrades: [10, 49, 50, 100, 255],
  },
  {
    path: "/login",
    component: SignIn,
    layout: "/auth",
    hidden: true,
  },
  {
    path: "/register",
    name: "Sign Up",
    icon: <RocketIcon color="inherit" />,
    secondaryNavbar: true,
    component: SignUp,
    layout: "/auth",
    hidden: true,
  },
];

export default dashRoutes;
