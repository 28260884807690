import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { EnvironmentProvider } from "context/Environement";
import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
import RTLLayout from "layouts/RTL.js";
import { AuthProvider, useAuth } from 'context/AuthContext';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated } = useAuth();

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? <Component {...props} /> : <Redirect to="/auth/login" />
      }
    />
  );
};

// Redirect logged-in users away from login page
const AuthRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated } = useAuth();

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? <Redirect to="/admin/dashboard" /> : <Component {...props} />
      }
    />
  );
};

ReactDOM.render(
  <Router> {/* Utilisation de `Router` */}
    <AuthProvider>
      <EnvironmentProvider>
        <Switch>
          <AuthRoute path={`/auth`} component={AuthLayout} />
          <ProtectedRoute path={`/admin`} component={AdminLayout} />
          <ProtectedRoute path={`/rtl`} component={RTLLayout} />
          <Redirect from={`/`} to="/admin/dashboard" />
        </Switch>
      </EnvironmentProvider>
    </AuthProvider>
  </Router>,
  document.getElementById("root")
);
;
