import React, { useEffect, useState } from 'react';
import { Box, Flex, Text, Avatar, Spinner, Badge, Image } from '@chakra-ui/react';
import axios from 'axios';

// BotInfo component
const BotInfo = () => {
  const [bots, setBots] = useState([]);
  const [loading, setLoading] = useState(true);

  // URLs of the bots
  const botUrls = [
    //'https://peaky.bot.mrapi.net/api/bot-info',
    //'http://51.158.60.90:65531/api/bot-info', // peakyemporium
    'http://51.158.60.90:65532/api/bot-info', // adp
    'https://adpanel.bot.mrapi.net/api/bot-info', // mrutilities
    //'https://wn.bot.mrapi.net/api/bot-info'
  ];

  // Fetch data from all the bot APIs
  const fetchBotData = async () => {
    try {
      const botResponses = await Promise.all(
        botUrls.map(url => axios.get(url).catch(error => ({ error })))  // Handle errors for each bot individually
      );

      const botsData = botResponses.map((res) => {
        if (res.error || !res.data) {
          // Fallback data when there is an error fetching a bot's info
          return {
            avatar: '/img/error.gif',
            username: '-',
            status: 'offline',
            activity: '-',
            activityType: null
          };
        }
        return res.data;
      });

      setBots(botsData);
    } catch (error) {
      console.error('Error fetching bot data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Fetch bot data initially
    fetchBotData();

    // Set up interval to fetch data every 10 seconds
    const intervalId = setInterval(fetchBotData, 10000); // 10 seconds

    // Clean up the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, []);

  // Helper function to render bot status
  const renderStatus = (status) => {
    switch (status.toLowerCase()) {
      case 'online':
        return <Badge colorScheme="green">En Ligne</Badge>;
      case 'idle':
        return <Badge colorScheme="yellow">Absent</Badge>;
      case 'dnd':
        return <Badge colorScheme="red">Ne pas Déranger</Badge>;
      case 'offline':
      default:
        return <Badge colorScheme="gray">Hors Ligne</Badge>;
    }
  };

  // Helper function to render activity type
  const renderActivityType = (activityType) => {
    switch (activityType) {
      case 0:
        return "Joue";
      case 1:
        return "Stream";
      case 2:
        return "Écoute";
      case 3:
        return "Regarde";
      default:
        return "-";
    }
  };

  if (loading) {
    return <Spinner size="xl" />;
  }

  // Static badge icon for Slash Commands
  const slashCommandsBadge = require('../static/badges/slash.png');

  return (
    <Flex direction="column" align="center" p={4} >

      <Flex wrap="wrap" justify="center">
        {bots.map((bot, index) => (
          <Box
            key={index}
            borderWidth="1px"
            borderRadius="lg"
            overflow="hidden"
            m={4}
            p={6}
            boxShadow="md"
            textAlign="center"
            border="1px solid"
            borderColor="purple.300"
          >
            <Avatar size="xl" objectFit="cover" src={bot.avatar || '/img/error.gif'} mb={4} />
            <Text fontWeight="bold" fontSize="xl">{bot.username || '-'}</Text>
            <Flex justify="center" align="center" mb={1}>
              <Image 
                src={slashCommandsBadge} 
                alt="Discord Slash Commands Badge" 
                boxSize="25px" 
              />
            </Flex>
            <Text mb={4}>{renderStatus(bot.status || 'offline')}</Text>
            {bot.activity && (
              <Text fontSize="sm" color="gray.400">
                {renderActivityType(bot.activityType)} {bot.activity || '-'}
              </Text>
            )}
          </Box>
        ))}
      </Flex>
    </Flex>
  );
};

export default BotInfo;
