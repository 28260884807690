import React, { useEffect, useState, useCallback } from 'react';
import { Box, Text, Image, Flex, Spinner, Heading, Badge, Skeleton } from '@chakra-ui/react';
import axios from 'axios';

const RadioTrackInfo = () => {
  const [qDanceNowPlaying, setQDNowPlaying] = useState(null);
  const [qDanceNextPlaying, setQDNextPlaying] = useState(null);
  const [qDancePreviousPlaying, setQDPreviousPlaying] = useState(null);
  const [qDancePrevious2Playing, setQDPrevious2Playing] = useState(null);
  const [radioFGFirstTrack, setRadioFGFirstTrack] = useState(null);
  const [radioFGPreviousTracks, setRadioFGPreviousTracks] = useState([]);
  const [slamHSFirstTrack, setSLAMHSFirstTrack] = useState(null);
  const [slamHSPreviousTracks, setSLAMHSPreviousTracks] = useState([]);
  const [loading, setLoading] = useState(true);

  // Flashing badge styles
  const dotAnimation = {
    '@keyframes blink': {
      '0%': { opacity: 0.5 },
      '50%': { opacity: 1 },
      '100%': { opacity: 0.5 }
    }
  };

  // Function to format timestamp into local 24-hour time
  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit'});
  };

  // Fetch radio data from APIs
  const fetchQDTrackData = useCallback(async () => {
    try {
      const response = await axios.get('https://feed.q-dance.com/onair');
      setQDNowPlaying(response.data.TrackData.NowPlaying);
      setQDNextPlaying(response.data.TrackData.NextPlaying);
      setQDPreviousPlaying(response.data.TrackData.PreviousPlaying);
      setQDPrevious2Playing(response.data.TrackData.History[1]);
    } catch (error) {
      console.error('Error fetching Q-Dance track data:', error);
    }
  }, []);

  const fetchRadioFGTrackData = useCallback(async () => {
    try {
      const response = await axios.get(`https://adpanel.api.mrapi.net/api/-/radio/tracking/radiofg`);
      setRadioFGFirstTrack(response.data[0]);
      setRadioFGPreviousTracks(response.data.slice(1, 4));
    } catch (error) {
      console.error('Error fetching Radio FG track data:', error);
    }
  }, []);

  const fetchRadioSLAMHSTrackData = useCallback(async () => {
    try {
      const response = await axios.get(`https://api.radioveronica.nl/api/nowplaying/playlist?stationKey=web11`);
      setSLAMHSFirstTrack(response.data.tracks[0]);
      setSLAMHSPreviousTracks(response.data.tracks.slice(1, 4));
    } catch (error) {
      console.error('Error fetching SLAM! track data:', error);
    }
  }, []);

  useEffect(() => {
    const fetchAllData = async () => {
      setLoading(true);
      await Promise.all([fetchQDTrackData(), fetchRadioFGTrackData(), fetchRadioSLAMHSTrackData()]);
      setLoading(false);
    };

    fetchAllData();
    const intervalId = setInterval(fetchAllData, 60000);
    return () => clearInterval(intervalId);
  }, [fetchQDTrackData, fetchRadioFGTrackData, fetchRadioSLAMHSTrackData]);

  if (loading) {
    return <Spinner size="xl" />;
  }

  if (!qDanceNowPlaying || !qDanceNextPlaying || !qDancePreviousPlaying || !radioFGFirstTrack || !radioFGPreviousTracks.length) {
    return <Text>Failed to load track data</Text>;
  }

  return (
    <Flex direction="row" h="calc(90vh - 80px)" justify="center" p={4} mt="60px" bg="transparent" borderRadius="md" overflow="hidden">
      {/* Q-Dance Card */}
      <Box p={4} borderWidth="1px"  border="1px solid" borderColor="purple.300"  borderRadius="lg" boxShadow="md" mb={4} mr={2}>
        <Flex justify="space-between" alignItems="center" mb={4}>
          <Heading as="h2" size="lg" mb={4}>Q-Dance Radio</Heading>
          <Badge colorScheme="red" variant="outline" p={2} display="flex" alignItems="center" ml={4}>
            <Box as="span" width="8px" height="8px" borderRadius="50%" bg="red" mr="8px" animation="blink 1.5s infinite" css={dotAnimation} />
            ON AIR
          </Badge>
        </Flex>
        <Flex align="center" mb={6}>
          <Skeleton isLoaded={!loading}>
            <Image
              boxSize="150px"
              objectFit="cover"
              src={qDanceNowPlaying.CoverImage || '/img/error.gif'}
              alt={`${qDanceNowPlaying.Title} - ${qDanceNowPlaying.Artist}`}
              mr={4}
            />
          </Skeleton>
          <Box>
            <Badge colorScheme="red" variant="outline" mb={2} animation="blink 1.5s infinite" css={dotAnimation}>
              ON AIR
            </Badge>
            <Skeleton isLoaded={!loading}>
              <Text fontWeight="bold" fontSize="xl">{qDanceNowPlaying?.Title}</Text>
            </Skeleton>
            <Skeleton isLoaded={!loading}>
              <Text fontSize="lg">{qDanceNowPlaying?.Artist}</Text>
            </Skeleton>
          </Box>
        </Flex>
        {/* Next Playing */}
        <Box>
          <Heading as="h3" size="md" mb={2} color="green.500" textAlign="center">Titre Suivant</Heading>
          <Flex align="center">
            <Image
              boxSize="100px"
              objectFit="cover"
              src={qDanceNextPlaying.CoverImage || '/img/error.gif'}
              alt={`${qDanceNextPlaying.Title} - ${qDanceNextPlaying.Artist}`}
              mr={4}
            />
            <Box>
              <Text fontWeight="bold">{qDanceNextPlaying.Title}</Text>
              <Text>{qDanceNextPlaying.Artist}</Text>
            </Box>
          </Flex>
        </Box>
        {/* Previous Playing */}
        <Box>
          <Heading as="h3" size="md" mb={2} color="blue.500" textAlign="center">Titres Passés</Heading>
          <Flex align="center">
            <Image
              boxSize="100px"
              objectFit="cover"
              src={qDancePreviousPlaying.CoverImage || '/img/error.gif'}
              alt={`${qDancePreviousPlaying.Title} - ${qDancePreviousPlaying.Artist}`}
              mr={4}
            />
            <Box>
              {(!qDancePreviousPlaying.Title && !qDancePreviousPlaying.Artist) ? (
                <Badge colorScheme="purple" variant="outline" fontSize="md">JINGEL - QDance</Badge>
              ) : (
                <>
                  <Text fontWeight="bold">{qDancePreviousPlaying.Title}</Text>
                  <Text>{qDancePreviousPlaying.Artist}</Text>
                </>
              )}
            </Box>
          </Flex>
          <Flex align="center">
            <Image
              boxSize="100px"
              objectFit="cover"
              src={qDancePrevious2Playing.CoverImage || '/img/error.gif'}
              alt={`${qDancePrevious2Playing.Title} - ${qDancePrevious2Playing.Artist}`}
              mr={4}
            />
            <Box>
              {(!qDancePrevious2Playing.Title && !qDancePrevious2Playing.Artist) ? (
                <Badge colorScheme="purple" variant="outline" fontSize="md">JINGEL - QDance</Badge>
              ) : (
                <>
                  <Text fontWeight="bold">{qDancePrevious2Playing.Title}</Text>
                  <Text>{qDancePrevious2Playing.Artist}</Text>
                </>
              )}
            </Box>
          </Flex>
        </Box>
      </Box>

      {/* Radio FG Card */}
      <Box p={4} borderWidth="1px"  border="1px solid" borderColor="purple.300"  borderRadius="lg" boxShadow="md" mb={4} mr={2}>
        <Flex justify="space-between" alignItems="center" mb={4}>
          <Heading as="h2" size="lg" mb={4}>Radio FG.</Heading>
          <Badge colorScheme="red" variant="outline" p={2} display="flex" alignItems="center" ml={4}>
            <Box as="span" width="8px" height="8px" borderRadius="50%" bg="red" mr="8px" animation="blink 1.5s infinite" css={dotAnimation} />
            ON AIR
          </Badge>
        </Flex>
        <Flex align="center" mb={6}>
          <Image
            boxSize="150px"
            objectFit="cover"
            src={radioFGFirstTrack.title.coverUrl || 'https://images.lesindesradios.fr/fit-in/300x2000/filters:format(webp)/radios/radiofg/radiostream/5gWkrl9VKE/vignette_XdEm8NzJHV.png'}
            alt={`${radioFGFirstTrack.title.title} - ${radioFGFirstTrack.title.artist}`}
            mr={4}
          />
          <Box>
            <Badge colorScheme="red" variant="outline" mb={2} animation="blink 1.5s infinite" css={dotAnimation}>ON AIR</Badge>
            <Text fontWeight="bold" fontSize="xl">{radioFGFirstTrack.title.title}</Text>
            <Text fontSize="lg">{radioFGFirstTrack.title.artist}</Text>
          </Box>
        </Flex>
        <Heading as="h3" size="md" mb={2} color="blue.500" textAlign="center">Titres Passés</Heading>
        {radioFGPreviousTracks.map((track, index) => (
          <Flex align="center" mb={4} key={index}>
            <Image
              boxSize="100px"
              objectFit="cover"
              src={track.title.coverUrl || 'https://images.lesindesradios.fr/fit-in/300x2000/filters:format(webp)/radios/radiofg/radiostream/5gWkrl9VKE/vignette_XdEm8NzJHV.png'}
              alt={`${track.title.title} - ${track.title.artist}`}
              mr={4}
            />
            <Box>
              <Text fontWeight="bold">{track.title.title}</Text>
              <Text>{track.title.artist}</Text>
              <Badge colorScheme="purple" variant="outline">{formatTimestamp(track.timestamp)}</Badge>
            </Box>
          </Flex>
        ))}
      </Box>

      {/* SLAM! HS Card */}
      <Box p={4} borderWidth="1px"  border="1px solid" borderColor="purple.300"  borderRadius="lg" boxShadow="md" mb={4} mr={2}>
        <Flex justify="space-between" alignItems="center" mb={4}>
          <Heading as="h2" size="lg" mb={4}>SLAM! HardStyle</Heading>
          <Badge colorScheme={(!slamHSFirstTrack || !slamHSPreviousTracks.length) ? "gray" : "red"} variant="outline" p={2} display="flex" alignItems="center" ml={4}>
            {(!slamHSFirstTrack || !slamHSPreviousTracks.length) ? (
              <Box as="span">OFF AIR</Box>
            ) : (
              <>
                <Box as="span" width="8px" height="8px" borderRadius="50%" bg="red" mr="8px" animation="blink 1.5s infinite" css={dotAnimation} />
                ON AIR
              </>
            )}
          </Badge>
        </Flex>
        {(!slamHSFirstTrack || !slamHSPreviousTracks.length) ? (
          <>
            <Text fontSize="xl" color="red.500" fontWeight="bold">Aïe.. l'api de SLAM! à un problème..</Text>
            <br />
            <Image
              boxSize="300px"
              objectFit="cover"
              src="https://api.radioveronica.nl/media/ndkaxzae/slam-hardstyle.jpg"
              alt="SLAM! Hardstyle"
              m="auto"
            />
            <br />
            <Text fontSize="xl" color="red.500" fontWeight="bold">API Error : tracks[] - - stationKey[web11]</Text>
            <Text fontSize="xl" color="red.500" fontWeight="bold">API system not founds from the api's</Text>
            <Text fontSize="xl" color="red.500" fontWeight="bold">Contact your Administrator ADPanel</Text>
          </>
        ) : (
          <>
            <Flex align="center" mb={6}>
              <Image
                boxSize="150px"
                objectFit="cover"
                src={slamHSFirstTrack.albumArt || 'https://api.radioveronica.nl/media/ndkaxzae/slam-hardstyle.jpg'}
                alt={`${slamHSFirstTrack.title} - ${slamHSFirstTrack.artist}`}
                mr={4}
              />
              <Box>
                <Badge colorScheme="red" variant="outline" mb={2} animation="blink 1.5s infinite" css={dotAnimation}>ON AIR</Badge>
                <Text fontWeight="bold" fontSize="xl">{slamHSFirstTrack.title}</Text>
                <Text fontSize="lg">{slamHSFirstTrack.artist}</Text>
              </Box>
            </Flex>
            <Heading as="h3" size="md" mb={2} color="blue.500" textAlign="center">Titres Passés</Heading>
            {slamHSPreviousTracks.map((track, index) => (
              <Flex align="center" mb={4} key={index}>
                <Image
                  boxSize="100px"
                  objectFit="cover"
                  src={track.albumArt || 'https://api.radioveronica.nl/media/ndkaxzae/slam-hardstyle.jpg'}
                  alt={`${track.title} - ${track.artist}`}
                  mr={4}
                />
                <Box>
                  <Text fontWeight="bold">{track.title || 'JINGEL'}</Text>
                  <Text>{track.artist || 'JINGEL'}</Text>
                  <Badge colorScheme="purple" variant="outline">{formatTimestamp(track.createdAt)}</Badge>
                </Box>
              </Flex>
            ))}
          </>
        )}
      </Box>
    </Flex>
  );
};

export default RadioTrackInfo;
