import React, { useEffect, useState } from 'react';
import { Flex, Box, VStack, HStack, Text, Badge, Button, IconButton } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { FaServer, FaHdd, FaMicrochip, FaMemory, FaPowerOff, FaHeart } from 'react-icons/fa';
import { MdAutorenew, MdDelete } from 'react-icons/md';
import { useAuth } from 'context/AuthContext';
import { useEnvironment } from 'context/Environement';

const DockerComponent = () => {
  const { token } = useAuth();
  const [environments, setEnvironments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const history = useHistory();
  const { setEnvironmentId } = useEnvironment();

  const handleEnvironmentClick = (environment) => {
    setEnvironmentId(environment.Id); // Définit l'ID dans le contexte
    history.push('/admin/!/Docker/e/Containers'); // Redirige vers la route fixe
  };
  

  // Fonction pour récupérer les environnements
  const fetchEnvironments = async () => {
    try {
      const response = await fetch('https://adpanel.api.mrapi.net/api/portainer-environments', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Erreur lors de la récupération des environnements');
      }

      const environments = await response.json();
      return environments;
    } catch (error) {
      console.error('Erreur lors de la récupération des environnements:', error);
      setError('Impossible de récupérer les environnements.');
      return [];
    }
  };

  useEffect(() => {
    const getEnvironments = async () => {
      try {
        setLoading(true);
        const envData = await fetchEnvironments();
        setEnvironments(envData);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    getEnvironments();
  }, []);


  // Fonction pour convertir la RAM en GB
  const convertToGB = (bytes) => {
    return (bytes / (1024 ** 3)).toFixed(2);  // Convertir les octets en gigaoctets et garder 2 décimales
  };

  if (loading) {
    return <Text>Chargement des informations des environnements...</Text>;
  }

  if (error) {
    return <Text color="red.500">{error}</Text>;
  }

  return (
    <Flex direction="column" p={4} mt="60px"  borderColor="purple.300" bg="transparent" borderRadius="md" overflow="hidden">
      <VStack spacing={4} borderColor="purple.300" align="start" width="100%">
        {environments.map((environment) => (
          <Box key={environment.Id} borderColor="purple.300" borderWidth="1px" borderRadius="lg" p={4} width="100%">
            <HStack justifyContent="space-between" align="center">
              <VStack align="start">
                <HStack>
                  <Text fontSize="xl" fontWeight="bold">{environment.Name}</Text>
                  {environment.Status === 1 ? (
                    <Badge variant="outline" colorScheme="green">En Ligne</Badge>
                  ) : (
                    <Badge variant="outline" colorScheme="red">Hors Ligne</Badge>
                  )}
                </HStack>
                <HStack spacing={6} mt={2}>
                  <Text fontSize="sm">Docker Version: {environment.Snapshots[0].DockerVersion || 'Unknown'}</Text>
                  <Text fontSize="sm">API Version: {environment.Snapshots[0].DockerSnapshotRaw.Version.ApiVersion || 'Unknown'}</Text>
                  <Text fontSize="sm">MinAPI Version: {environment.Snapshots[0].DockerSnapshotRaw.Version.MinAPIVersion || 'Unknown'}</Text>
                  <Text fontSize="sm">GitCommit: {environment.Snapshots[0].DockerSnapshotRaw.Version.GitCommit || 'Unknown'}</Text>
                  <Text fontSize="sm">Socket: {environment.URL || 'No Url'}</Text>
                </HStack>
                <HStack spacing={6} mt={2}>
                  <Text fontSize="sm"><FaServer /> {environment.Snapshots[0].ContainerCount || 0} containers</Text>
                  <Text fontSize="sm"><FaHdd /> {environment.Snapshots[0].VolumeCount || 0} volumes</Text>
                  <Text fontSize="sm"><FaMicrochip /> {environment.Snapshots[0].TotalCPU || 0} CPUs</Text>
                  {/* Conversion de la RAM en GB */}
                  <Text fontSize="sm"><FaMemory /> {convertToGB(environment.Snapshots[0].TotalMemory || 0)} GB RAM</Text>
                </HStack>
                <HStack spacing={6} mt={2}>
                  {/* Icônes de statut des conteneurs déplacées à droite de la RAM */}
                  <Text fontSize="sm"><FaPowerOff color="green" /> {environment.Snapshots[0].RunningContainerCount || 0}</Text>
                  <Text fontSize="sm"><FaPowerOff color="red" /> {environment.Snapshots[0].StoppedContainerCount || 0}</Text>
                  <Text fontSize="sm"><FaHeart color="green" /> {environment.Snapshots[0].HealthyContainerCount || 0}</Text>
                  <Text fontSize="sm"><FaHeart color="orange" /> {environment.Snapshots[0].UnhealthyContainerCount || 0}</Text>
                </HStack>
              </VStack>

              <HStack spacing={3}>
                <Button colorScheme="orange" onClick={() => handleEnvironmentClick(environment)}>
                  Détails
                </Button>
              </HStack>
            </HStack>
          </Box>
        ))}
      </VStack>
    </Flex>
  );
};

export default DockerComponent;
