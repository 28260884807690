// Chakra imports
import {
    Flex,
    Grid,
    Box,
    Image,
    SimpleGrid,
    useColorModeValue,
  } from "@chakra-ui/react";
  // assets
  import peopleImage from "assets/img/people-image.png";
  import logoChakra from "assets/svg/logo-white.svg";
  import BarChart from "components/Charts/BarChart";
  import LineChart from "components/Charts/LineChart";

  //Custom

import StatsArgent from "./components/StatsArgent";
  // Custom icons
  import {
    CartIcon,
    DocumentIcon,
    GlobeIcon,
    WalletIcon,
  } from "components/Icons/Icons.js";
  import React from "react";
  import { dashboardTableData, timelineData } from "variables/general";
  import ActiveUsers from "./components/ActiveUsers";
  import BuiltByDevelopers from "./components/BuiltByDevelopers";
  import MiniStatistics from "./components/MiniStatistics";
  import TimeLineItem from "./components/TimeLineItem";
  import Projects from "./components/Projects";
  import SalesOverview from "./components/SalesOverview";
  import WorkWithTheRockets from "./components/WorkWithTheRockets";
import CalculetteArgentSale from "./components/CalculetteArgentSale";
import CalculatriceAS from "./components/CalculatriceAS";
import UserOnlineServer from "./components/UserOnlineServer";
import StatusComponent from "./components/StatusCFXRE";
import BotInfo from "./components/DiscordBotListStatus";
import AdminBotInfo from "./components/DiscordAdminListStatus";
import RadioClock from "./components/RadioClock";
import ConnectedUserCard from "./components/ConnectedUserDiscord";
  
  export default function Dashboard() {
    const iconBoxInside = useColorModeValue("white", "white");
  
    return (
      <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
        <RadioClock 
              color="red" 
              bgColor="transparent" 
              fontSize="4xl"
              borderColor="red.500" 
              border="1px solid"
        />
        <ConnectedUserCard/>
        <BotInfo />
        <AdminBotInfo />
      </Flex>
  );
}