import React, { useState, useEffect, useRef } from 'react';
import { Flex, Box, Button, Grid, Heading, Text, Spinner, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, useDisclosure, Textarea, IconButton, Icon } from '@chakra-ui/react';
import { IoTerminal, IoHeart } from "react-icons/io5";
import axios from 'axios';

const CustomCommands = () => {
    const [commandOutput, setCommandOutput] = useState('');
    const [serviceStatus, setServiceStatus] = useState({});
    const [loading, setLoading] = useState(false);
    const [logs, setLogs] = useState({});
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [currentService, setCurrentService] = useState('');
    const [autoScroll, setAutoScroll] = useState(true); // État pour activer/désactiver le défilement automatique
    const [serverStatus, setServerStatus] = useState('unknown'); // État pour le statut du serveur dédié
    const [serverLoading, setServerLoading] = useState(true); // Spinner pour la requête du statut du serveur dédié

    // Référence pour la zone de texte des logs
    const textareaRef = useRef(null);

    // Predefined commands
    const commands = [
        { name: 'Restart Bot ADP', command: 'systemctl restart adpbot' },
        { name: 'Restart Bot MrBot', command: 'systemctl restart mrbot' },
        { name: 'Restart Nginx', command: 'systemctl restart nginx' },
        { name: 'Restart Wings', command: 'systemctl restart wings' },
        { name: 'Restart Docker', command: 'systemctl restart docker' }
    ];

    // Fonction pour exécuter les commandes prédéfinies
    const executeCommand = async (command) => {
        setLoading(true);
        try {
            const response = await axios.post('https://adpanel.bot.mrapi.net/api/execute-command', { command });
            setCommandOutput(response.data.output);
        } catch (error) {
            setCommandOutput('Error executing command');
        }
        setLoading(false);
    };

    // Fonction pour récupérer le statut d'un service
    const fetchServiceStatus = async (service) => {
        try {
            const response = await axios.get(`https://adpanel.bot.mrapi.net/api/service-status?service=${service}`);
            setServiceStatus((prev) => ({
                ...prev,
                [service]: response.data.status
            }));
        } catch (error) {
            setServiceStatus((prev) => ({
                ...prev,
                [service]: 'error' // Mark service with error status
            }));
        }
    };

    // Fonction pour récupérer les logs d'un service
    const fetchServiceLogs = async (service) => {
        try {
            const response = await axios.get(`https://adpanel.bot.mrapi.net/api/service-logs?service=${service}`);
            setLogs((prev) => ({
                ...prev,
                [service]: response.data.logs
            }));
        } catch (error) {
            setLogs((prev) => ({
                ...prev,
                [service]: 'Error fetching logs'
            }));
        }
    };

    // Fonction pour vérifier le statut du serveur dédié
    const fetchServerStatus = async () => {
        setServerLoading(true);
        try {
            const response = await axios.get('https://adpanel.bot.mrapi.net/api/server-status'); // Remplace par l'URL de ton API
            setServerStatus(response.data.status); // Met à jour le statut du serveur
        } catch (error) {
            setServerStatus('offline'); // Si une erreur survient, le serveur est considéré hors ligne
        }
        setServerLoading(false);
    };

    // Scroll automatiquement à la dernière ligne des logs si autoScroll est activé
    useEffect(() => {
        if (autoScroll && textareaRef.current) {
            textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
        }
    }, [logs, autoScroll]);

    // Détecter lorsque l'utilisateur scrolle manuellement
    const handleScroll = () => {
        const textarea = textareaRef.current;
        if (textarea) {
            const isAtBottom = textarea.scrollHeight - textarea.scrollTop === textarea.clientHeight;
            setAutoScroll(isAtBottom);
        }
    };

    // Fetch status of multiple services every 10 seconds
    useEffect(() => {
        const services = ['adpbot', 'mrbot', 'peakybot', 'nginx', 'mysql', 'wings', 'docker', 'adpanelapi', 'apiadp', 'peakyapi','apache2', 'webmin', 'fail2ban', 'containerd', 'apiop', 'gitws', 'pteroq', 'wssrt', 'wsstats'];

        const fetchAllServices = () => {
            services.forEach(fetchServiceStatus);
        };

        fetchAllServices(); // Fetch initially
        const intervalId = setInterval(fetchAllServices, 10000); // Every 10 seconds

        return () => clearInterval(intervalId); // Cleanup
    }, []);

    // Fetch server status every 10 seconds
    useEffect(() => {
        fetchServerStatus(); // Appel initial pour le statut du serveur
        const intervalId = setInterval(fetchServerStatus, 30000); // Mettre à jour le statut toutes les 10 secondes
        return () => clearInterval(intervalId); // Cleanup
    }, []);

    // Ouvrir le modal des logs pour un service
    const handleOpenLogs = (service) => {
        setCurrentService(service);
        onOpen();
        fetchServiceLogs(service);
        const intervalId = setInterval(() => fetchServiceLogs(service), 5000); // Fetch logs every 5 seconds
        return () => clearInterval(intervalId); // Cleanup on modal close
    };

    // Indicateur visuel pour le statut général du serveur
    const getStatusColor = (status) => {
        if (!status) {
            return 'gray.400';  // Default color when status is undefined
        }

        switch (status.toLowerCase()) {
            case 'active':
                return 'green.400';  // Green for active
            case 'inactive':
            case 'failed':
                return 'red.400';    // Red for inactive or failed
            case 'activating':
            case 'deactivating':
                return 'orange.400'; // Orange for activating or deactivating
            case 'error':
                return 'red.500';    // Bright red for error
            default:
                return 'gray.400';   // Default gray for unknown status
        }
    };

    return (
        <Flex direction="column" p={4} mt="60px" bg="transparent" borderRadius="md" overflow="hidden">
            {/* Commands Box */}
            <Box mb={8} p={4} border="1px solid" borderColor="purple.300" borderRadius="md">
                <Heading as="h3" size="md" mb={4}>Commands</Heading>
                <Grid templateColumns="repeat(8, 1fr)" gap={4}>
                    {commands.map((cmd, index) => (
                        <Button
                            key={index}
                            onClick={() => executeCommand(cmd.command)}
                            colorScheme="purple"
                            variant='outline'
                            isDisabled={loading}
                        >
                            {loading ? <Spinner size="sm" /> : cmd.name}
                        </Button>
                    ))}
                </Grid>

                {/* Output of command */}
                <Box mt={4} bg="gray.700" p={3} borderRadius="md" color="white">
                    <Text fontSize="lg">Output:</Text>
                    <Text>{commandOutput || ''}</Text>
                </Box>
                <br />
                <Flex align="center" gap={3}>
                    <Text fontSize="md">Server MariaBird.NET :</Text>
                    {serverLoading ? (
                        <Spinner size="sm" color="purple.500" />
                    ) : (
                        <Icon as={IoHeart} w={6} h={6} color={serverStatus === 'online' ? 'green.400' : 'red.400'} />
                    )}
                    <Text fontSize="md" fontWeight="bold" color={serverStatus === 'online' ? 'green.400' : 'red.400'}>
                        {serverStatus === 'online' ? 'Vivant' : 'Mort'}
                    </Text>
                </Flex>
            </Box>

            {/* Service Status Box */}
            <Box p={4} border="1px solid" borderColor="purple.300" borderRadius="md">
                <Heading as="h3" size="md" mb={4}>Service Status</Heading>
                <Grid templateColumns="repeat(8, 1fr)" gap={4}>
                    {['adpbot', 'mrbot', 'peakybot', 'nginx', 'mysql', 'wings', 'docker', 'adpanelapi', 'apiadp', 'peakyapi','apache2', 'webmin', 'fail2ban', 'containerd', 'apiop', 'gitws', 'pteroq', 'wssrt', 'wsstats'].map((service, index) => (
                        <Box key={index} p={2} border="1px solid" borderColor="purple.300" borderRadius="md">
                            <Flex justify="space-between" align="center">
                                <Text>{service.toUpperCase()}</Text>
                                <Flex align="center" gap={3}>
                                    <IconButton
                                        icon={<IoTerminal />}
                                        size="lg"
                                        variant="ghost"
                                        onClick={() => handleOpenLogs(service)}
                                        aria-label={`View logs for ${service}`}
                                        _hover={{ color: 'teal.400', cursor: 'pointer' }}
                                    />
                                    <Box
                                        w={4}
                                        h={4}
                                        bg={getStatusColor(serviceStatus[service])}
                                        borderRadius="50%"
                                        display="inline-block"
                                    >
                                        {!serviceStatus[service] && <Spinner size="sm" color="gray.500" />}
                                    </Box>
                                </Flex>
                            </Flex>
                        </Box>
                    ))}
                </Grid>
            </Box>

            {/* Modal for logs */}
            <Modal isOpen={isOpen} onClose={onClose} size="xl">
                <ModalOverlay />
                <ModalContent maxH="100vw" maxW="60vw">
                    <ModalHeader>{currentService.toUpperCase()} Logs (Last 2 Days)</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Textarea
                            value={logs[currentService] || 'Loading logs...'}
                            readOnly
                            rows={20}
                            bg="black"
                            color="orange"
                            fontFamily="Inconsolata"
                            height="60vh"
                            ref={textareaRef}
                            onScroll={handleScroll}
                            sx={{
                                whiteSpace: "pre-wrap",
                                fontVariantLigatures: "none",
                            }}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="red" mr={3} onClick={onClose}>Close</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Flex>
    );
};

export default CustomCommands;
