import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Button,
  Flex,
  CheckboxGroup,
  Checkbox
} from '@chakra-ui/react';
import axios from 'axios';
import { useAuth } from 'context/AuthContext';
import { roles } from 'context/Permissions/RolesPermissions'; // Assurez-vous que le chemin est correct

const EditUserModal = ({ isOpen, onClose, userId }) => {
  const { token, userDetails } = useAuth(); // Inclure userDetails pour vérifier les droits
  const [name, setName] = useState('');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [password, setPassword] = useState('');
  const [selectedGrades, setSelectedGrades] = useState([]);

  useEffect(() => {
    if (userId) {
      const fetchUser = async () => {
        try {
          const response = await axios.get(`https://adpanel.api.mrapi.net/user/${userId}`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
          const user = response.data;
          setName(user.name);
          setFirstname(user.firstname);
          setLastname(user.lastname);
          // Convertir le grade en tableau pour la sélection
          const userGrades = Object.keys(roles).filter(role => roles[role].grade === user.grade);
          setSelectedGrades(userGrades);
        } catch (error) {
          console.error('Erreur lors de la récupération des détails de l’utilisateur', error);
        }
      };
      fetchUser();
    }
  }, [userId, token]);

  const handleUpdateUser = async () => {
    try {
      const grade = selectedGrades.length > 0 ? roles[selectedGrades[0]].grade : 0;
      await axios.put(`http://mariabird.net:41240/user/${userId}`, {
        name,
        firstname,
        lastname,
        password,
        grade
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      onClose(); // Fermer le modal après la mise à jour
    } catch (error) {
      console.error('Erreur lors de la mise à jour de l’utilisateur', error);
    }
  };

  const handleGradeChange = (values) => {
    setSelectedGrades(values);
  };

  // Vérifier si l'utilisateur a les droits nécessaires
  const isAuthorized = userDetails?.grade === 255 || userDetails?.grade === 100;

  // Vérifier si l'utilisateur est de grade 94 ou 95
  const isGradeRestricted = userDetails?.grade === 94 || userDetails?.grade === 95;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Éditer l'Utilisateur</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl mb={4}>
            <FormLabel>Discord Name</FormLabel>
            <Input
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Prénom</FormLabel>
            <Input
              value={firstname}
              onChange={(e) => setFirstname(e.target.value)}
            />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Nom de famille</FormLabel>
            <Input
              value={lastname}
              onChange={(e) => setLastname(e.target.value)}
            />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Mot de Passe</FormLabel>
            <Input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Grade</FormLabel>
            <CheckboxGroup value={selectedGrades} onChange={handleGradeChange}>
              <Flex direction="column">
                {Object.keys(roles).map((roleKey) => {
                  const roleGrade = roles[roleKey].grade;
                  const isCurrentUserGrade = roleGrade === roles[selectedGrades[0]]?.grade;
                  const isHigherGrade = roleGrade > (userDetails?.grade || 0);
                  const isDisabled = isGradeRestricted && (isHigherGrade || isCurrentUserGrade);

                  return (
                    <Checkbox
                      key={roleKey}
                      value={roleKey}
                      isDisabled={isDisabled}
                      isChecked={selectedGrades.includes(roleKey)}
                    >
                      {roleKey}
                    </Checkbox>
                  );
                })}
              </Flex>
            </CheckboxGroup>
          </FormControl>
          <Flex mt={4} justifyContent='flex-end'>
            <Button colorScheme='blue' onClick={handleUpdateUser}>
              Mettre à jour
            </Button>
            <Button ml={3} onClick={onClose}>
              Annuler
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default EditUserModal;
