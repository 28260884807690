import React, { createContext, useContext, useState } from 'react';

const EnvironmentContext = createContext();

export const useEnvironment = () => useContext(EnvironmentContext);

export const EnvironmentProvider = ({ children }) => {
  const [environmentId, setEnvironmentId] = useState(null);

  return (
    <EnvironmentContext.Provider value={{ environmentId, setEnvironmentId }}>
      {children}
    </EnvironmentContext.Provider>
  );
};
