import React, { useEffect, useState } from 'react';
import { Box, Text, VStack, Button, Badge, HStack } from '@chakra-ui/react';
import { useAuth } from 'context/AuthContext';
import { useEnvironment } from 'context/Environement';

const ContainerList0 = () => {
  const { token } = useAuth();
  const { environmentId } = useEnvironment(); // Utilise l'ID du contexte
  const [containers, setContainers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchContainers = async () => {
    try {
      const response = await fetch(`https://adpanel.api.mrapi.net/api/portainer-environments/${environmentId}/containers`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Erreur lors de la récupération des conteneurs');
      }

      const data = await response.json();
      setContainers(data);
    } catch (error) {
      console.error('Erreur lors de la récupération des conteneurs:', error);
      setError('Impossible de récupérer les conteneurs.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!environmentId) return; // Vérifie que l'ID est bien défini
    setLoading(true);
    fetchContainers();
  }, [environmentId]); // Exécute l'effet à chaque changement d'ID dans le contexte

  if (loading) {
    return <Text>Chargement des conteneurs...</Text>;
  }

  if (error) {
    return <Text color="red.500">{error}</Text>;
  }

  return (
    <VStack spacing={4} align="start" p={4}>
      <Text fontSize="2xl" fontWeight="bold">Conteneurs de l'environnement {environmentId}</Text>
      {containers.map((container) => (
        <Box key={container.Id} borderWidth="1px" borderRadius="lg" p={4} width="100%">
          <HStack justifyContent="space-between">
            <VStack align="start">
              <Text fontSize="lg" fontWeight="bold">{container.Names[0]}</Text>
              <HStack>
                <Badge colorScheme={container.State === 'running' ? 'green' : 'red'}>
                  {container.State === 'running' ? 'En cours d\'exécution' : 'Arrêté'}
                </Badge>
                <Text fontSize="sm">Image: {container.Image}</Text>
                <Text fontSize="sm">Ports: {container.Ports.map(port => `${port.PublicPort}:${port.PrivatePort}`).join(', ')}</Text>
              </HStack>
            </VStack>
            <Button colorScheme="blue" onClick={() => alert(`Détails du conteneur ${container.Id}`)}>Détails</Button>
          </HStack>
        </Box>
      ))}
    </VStack>
  );
};

export default ContainerList0;
