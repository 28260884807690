import React, { useState } from 'react';
import { Box, Button, Stack, Text, Input } from '@chakra-ui/react';
import axios from 'axios';

const KillSwitch = () => {
    const [service, setService] = useState('');
    const [message, setMessage] = useState('');

    const handleAction = async (action) => {
        try {
            const url = action === 'stop' 
                ? '/api/service/stop' 
                : '/api/service/start';
            const response = await axios.post(`http://localhost:3000${url}`, { service });
            setMessage(response.data.message);
        } catch (error) {
            setMessage(error.response.data.message || 'Erreur');
        }
    };

    const handleNetwork = async (status) => {
        try {
            const url = status === 'off' ? '/api/network/off' : '/api/network/on';
            const response = await axios.post(`http://localhost:3000${url}`);
            setMessage(response.data.message);
        } catch (error) {
            setMessage(error.response.data.message || 'Erreur');
        }
    };

    const handleMaintenance = async () => {
        try {
            const response = await axios.post('http://localhost:3000/api/maintenance');
            setMessage(response.data.message);
        } catch (error) {
            setMessage(error.response.data.message || 'Erreur');
        }
    };

    return (
        <Box p={5}>
            <Text fontSize="2xl" mb={5}>Kill Switch Manager</Text>
            <Stack spacing={3}>
                <Box>
                    <Text>Service à gérer :</Text>
                    <Input 
                        placeholder="Nom du service (e.g., apache2)" 
                        value={service} 
                        onChange={(e) => setService(e.target.value)} 
                    />
                    <Stack direction="row" mt={2}>
                        <Button colorScheme="red" onClick={() => handleAction('stop')}>Arrêter</Button>
                        <Button colorScheme="green" onClick={() => handleAction('start')}>Démarrer</Button>
                    </Stack>
                </Box>
                <Box>
                    <Text>Réseau :</Text>
                    <Stack direction="row">
                        <Button colorScheme="yellow" onClick={() => handleNetwork('off')}>Désactiver</Button>
                        <Button colorScheme="blue" onClick={() => handleNetwork('on')}>Activer</Button>
                    </Stack>
                </Box>
                <Box>
                    <Text>Maintenance :</Text>
                    <Button colorScheme="orange" onClick={handleMaintenance}>Activer Mode Maintenance</Button>
                </Box>
            </Stack>
            {message && <Box mt={5}><Text fontSize="lg">{message}</Text></Box>}
        </Box>
    );
};

export default KillSwitch;
