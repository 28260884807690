import React, { useEffect, useState, useRef } from 'react';
import { Box, Flex, Text, Button, useColorModeValue, useToast } from '@chakra-ui/react';

const LogsRTCBot = ({ height }) => {  // Passer la hauteur dynamiquement
    const [logs, setLogs] = useState('');
    const logsEndRef = useRef(null);
    const logsContainerRef = useRef(null);
    const toast = useToast();

    const backgroundColor = useColorModeValue('gray.800', 'gray.900');
    const textColor = useColorModeValue('green.300', 'green.500');

    useEffect(() => {
        const socket = new WebSocket('ws://51.158.60.90:4547/');

        socket.onmessage = (event) => {
            const cleanData = event.data
                .split('\n')
                .map((line) =>
                    line.replace(/sd-175382 DiscordBotRTC\[\d+\]:\s*/, '') // Retirer uniquement la partie "sd-175382 DiscordBotRTC[xxxxxxx]: "
                )
                .join('\n');
            setLogs((prevLogs) => prevLogs + '\n' + cleanData);
        };

        socket.onerror = (error) => {
            console.error('WebSocket error:', error);
        };

        return () => socket.close();
    }, []);

    useEffect(() => {
        if (logsEndRef.current && logsContainerRef.current) {
            logsContainerRef.current.scrollTop = logsContainerRef.current.scrollHeight;
        }
    }, [logs]);

    const handleCaptureLogs = () => {
        const logsArray = logs.split('\n');
        const lastTenLogs = logsArray.slice(-10).join('\n');

        navigator.clipboard.writeText(lastTenLogs).then(() => {
            toast({
                title: 'Logs copied!',
                description: 'The last 10 lines of logs have been copied to the clipboard.',
                status: 'success',
                duration: 3000,
                isClosable: true,
                position: 'bottom-right',
                variant: 'outline',
                colorScheme: 'blue',
            });
        }).catch(() => {
            toast({
                title: 'Failed to copy logs.',
                description: 'There was an issue copying the logs to the clipboard.',
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: 'bottom-right',
            });
        });
    };

    const renderLogs = (logData) => {
        return logData.split('\n').map((line, index) => {
            let color = textColor;

            if (line.includes('[RTC STATUS]')) color = 'orange.400';
            if (line.includes('[RTC SERVER]')) color = 'purple.500';
            if (line.includes('[RTC ERROR]')) color = 'red.500';

            return (
                <Text key={index} color={color} fontFamily="monospace" whiteSpace="pre-wrap">
                    {line}
                </Text>
            );
        });
    };

    return (
        <Flex direction="column" p={4} mt="60px" bg="transparent" borderRadius="md" overflow="hidden">
            <Box
                ref={logsContainerRef}
                bg={backgroundColor}
                color={textColor}
                borderRadius="md"
                p={4}
                overflowY="scroll"
                fontFamily="monospace"
                whiteSpace="pre-wrap"
                boxShadow="lg"
                position="relative"
            >
                {renderLogs(logs)}
                <div ref={logsEndRef} />

                {/* Capture Button */}
                <Button
                    onClick={handleCaptureLogs}
                    colorScheme="red"
                    variant="outline"
                    size="sm"
                    position="absolute"
                    bottom="10px"
                    right="10px"
                    zIndex={10}
                >
                    Capture
                </Button>
            </Box>
        </Flex>
    );
};

export default LogsRTCBot;
