import React, { useState } from 'react';
import { Box, Button, VStack, HStack, useToast, Flex, Text } from '@chakra-ui/react';
import MonacoEditor from '@monaco-editor/react';
import Autocomplete from 'react-autocomplete';
import { useAuth } from 'context/AuthContext';
import { useHistory } from 'react-router-dom';

const CodeEditor = () => {
  const { token } = useAuth();
  const [code, setCode] = useState('// Start editing code...');
  const [filePath, setFilePath] = useState('/');
  const [fileSuggestions, setFileSuggestions] = useState([]);
  const toast = useToast();
  const history = useHistory();

  const handleEditorChange = (value) => {
    setCode(value);
  };

  const fetchFileSuggestions = async (query) => {
    try {
      const response = await fetch(`https://adpanel.bot.mrapi.net/api/search-files?query=${query}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setFileSuggestions(data.files || []);
    } catch (error) {
      console.error('Erreur lors de la recherche des fichiers:', error);
      setFileSuggestions([]);
    }
  };

  const handleFileSelect = (val) => {
    if (val.endsWith('/')) {
      setFilePath(val);
      fetchFileSuggestions(val);
    } else {
      setFilePath(val);
    }
  };

  const openFile = async () => {
    try {
      const response = await fetch(`https://adpanel.bot.mrapi.net/api/open-file?path=${filePath}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Erreur lors de la récupération du fichier.');
      }
      const data = await response.json();
      setCode(data.content);
      toast({
        title: 'Fichier ouvert.',
        description: `Le fichier ${filePath} a été chargé avec succès.`,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      history.push(`/admin/!/vscode/edit?path=${encodeURIComponent(filePath).replace(/%2F/g, '/')}`);
      
    } catch (error) {
      toast({
        title: 'Erreur.',
        description: error.message || "Impossible d'ouvrir le fichier.",
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const saveFile = async () => {
    try {
      const response = await fetch('https://adpanel.bot.mrapi.net/api/save', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          path: filePath,
          content: code,
        }),
      });
      if (!response.ok) {
        throw new Error('Erreur lors de la sauvegarde du fichier.');
      }
      toast({
        title: 'Fichier enregistré.',
        description: `Le fichier ${filePath} a été enregistré avec succès.`,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Erreur.',
        description: error.message || "Impossible d'enregistrer le fichier.",
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const getFileName = (path) => path ? path.split('/').pop() : '';

  return (
    <Flex direction="column" justify="flex-start" align="center" minH="calc(100vh - 80px)" mt="80px" p={4}>
      <VStack spacing={4} width="100%" flex="1">
        <HStack width="100%" zIndex={10} justify="space-between">
          <HStack>
            <Autocomplete
              getItemValue={(item) => item}
              items={fileSuggestions || []}
              renderItem={(item, isHighlighted) =>
                <div style={{
                  background: isHighlighted ? '#333' : '#222',
                  padding: '5px',
                  color: '#fff',
                  zIndex: 10
                }}>
                  {item}
                </div>
              }
              value={filePath}
              onChange={(e) => {
                const value = e.target.value;
                if (value === '') {
                  setFilePath('/');
                } else {
                  setFilePath(value);
                  fetchFileSuggestions(value);
                }
              }}
              onSelect={(val) => handleFileSelect(val)}
              menuStyle={{
                borderRadius: '3px',
                boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
                background: '#1e1e1e',
                padding: '2px 0',
                fontSize: '90%',
                position: 'absolute',
                overflow: 'auto',
                maxHeight: '50%',
                zIndex: 10
              }}
              inputProps={{
                placeholder: 'Chemin du fichier à ouvrir',
                style: {
                  flex: 1,
                  padding: '8px',
                  borderRadius: '4px',
                  border: '1px solid purple',
                  borderColor:"purple.300",
                  backgroundColor: '#1e1e1e',
                  color: '#fff',
                  outline: 'none',
                }
              }}
            />
            <Button colorScheme="teal" onClick={openFile}>
              Ouvrir
            </Button>
            <Button colorScheme="blue" onClick={saveFile}>
              Enregistrer
            </Button>
          </HStack>

          <Text color="gray.400">
            Vous éditez : <strong>{getFileName(filePath)}</strong>
          </Text>
        </HStack>

        <Box width="100%" height="75vh" border="1px solid gray" borderRadius="md"  borderColor="purple.300" overflow="hidden">
          <MonacoEditor
            height="100%"
            language="javascript"
            value={code}
            onChange={handleEditorChange}
            theme="vs-dark"
          />
        </Box>
      </VStack>
    </Flex>
  );
};

export default CodeEditor;
