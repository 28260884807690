import React, { useEffect, useState } from 'react';
import { Box, Flex, Text, Avatar, Spinner, Badge, Image, Center } from '@chakra-ui/react';
import axios from 'axios';

// AdminBotInfo component
const AdminBotInfo = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  const badgeIcons = {
    4194304: require('../static/badges/22.png'),
    64: require('../static/badges/6.png'),
    128: require('../static/badges/7.png'),
    256: require('../static/badges/8.png'),
  };

  const badgeMap = {
    1: "Discord Employee",
    2: "Discord Partner",
    4: "HypeSquad Events",
    8: "Bug Hunter Level 1",
    16: "MFA SMS",  // Not public
    32: "Nitro Promo Dismissed",  // Not public
    64: "House Bravery",
    128: "House Brilliance",
    256: "House Balance",
    512: "Early Supporter",
    1024: "Team User",
    2048: "Internal Application",  // Not public
    4096: "System User",
    8192: "Has Unread Urgent Messages",  // Not public
    16384: "Bug Hunter Level 2",
    32768: "Underage Deleted",  // Not public
    65536: "Verified Bot",
    131072: "Early Verified Bot Developer",
    262144: "Moderator Programs Alumni",
    524288: "Bot with Interactions Endpoint",
    1048576: "Spammer",  // Not public
    2097152: "Disable Nitro Features",  // Not public
    4194304: "Active Developer",
    // The rest are not public or are not user-related flags
    8589934592: "High Global Rate Limit",  // Not public
    17179869184: "Deleted",  // Not public
    34359738368: "Disabled Suspicious Activity",  // Not public
    68719476736: "Self Deleted",  // Not public
    137438953472: "Premium Discriminator",  // Not public
    274877906944: "Used Desktop Client",  // Not public
    549755813888: "Used Web Client",  // Not public
    1099511627776: "Used Mobile Client",  // Not public
    2199023255552: "Disabled",  // Not public
    8796093022208: "Verified Email",  // Not public
    17592186044416: "Quarantined",  // Not public
    1125899906842624: "Collaborator",  // Not public
    2251799813685248: "Restricted Collaborator",  // Not public
};

const getBadgeIcons = (flags) => {
  let icons = [];
  for (let flagValue in badgeIcons) {
      if ((flags & flagValue) === parseInt(flagValue)) {
          icons.push(badgeIcons[flagValue]);
      }
  }
  return icons;
};

  // Fetch users from API
  const userUrl = 'https://adpanel.bot.mrapi.net/api/-/secure/-/255/app/AdminUsers-Info';

  // Fetch data
  const fetchUserData = async () => {
    try {
      const response = await axios.get(userUrl);
      const guilds = response.data;
      const allUsers = guilds.flatMap(guild => guild.members);
      setUsers(allUsers);
    } catch (error) {
      console.error('Error fetching user data:', error);
      setUsers([{
        avatar: '/img/error.gif',
        name: '-',
        status: 'offline',
        discriminator: '-',
        activity: '',
        activityType: '',
      }]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserData();
    const intervalId = setInterval(fetchUserData, 10000);
    return () => clearInterval(intervalId);
  }, []);

  // Helper function to render user status
  const renderStatus = (status) => {
    switch (status.toLowerCase()) {
      case 'online':
        return <Badge colorScheme="green">En Ligne</Badge>;
      case 'idle':
        return <Badge colorScheme="yellow">Absent</Badge>;
      case 'dnd':
        return <Badge colorScheme="red">Ne pas Déranger</Badge>;
      case 'offline':
      default:
        return <Badge colorScheme="gray">Hors Ligne</Badge>;
    }
  };

  const renderActivity = (activityType, customStatus, activity, statusBody) => {
    const type = activityType === '' ? 0 : activityType;

    switch (parseInt(type)) {
      case 0:
        return `Joue à ${activity || statusBody || 'No activity'} - ${statusBody}`;
      case 1:
        return `Stream ${activity || 'No activity'}`;
      case 2:
        return `Écoute ${activity || 'No activity'}`;
      case 3:
        return `Regarde ${activity || 'No activity'}`;
      case 4:
        return `${customStatus || 'No status'}`;
      case 5:
        return `Competing in ${activity || 'No activity'}`;
      default:
        return 'No activity';
    }
  };

  if (loading) {
    return <Spinner size="xl" />;
  }

  return (
    <Flex direction="column" align="center" p={4}>
      <Flex wrap="wrap" justify="center">
        {users.map((user, index) => (
          <Box
            key={index}
            borderWidth="1px"
            borderRadius="lg"
            overflow="hidden"
            m={4}
            p={6}
            boxShadow="md"
            textAlign="center"
            border="1px solid"
            borderColor="purple.300"
          >
            <Avatar size="xl" objectFit="cover" src={user.avatar || '/img/error.gif'} mb={4} />
            <Text fontWeight="bold" fontSize="xl">{user.name || '-'}</Text>
            <Flex justify="center" align="center" mb={4}>
              {getBadgeIcons(user.flags).map((icon, iconIndex) => (
                <Image 
                  key={iconIndex} 
                  src={icon} 
                  alt="Badge Icon" 
                  boxSize="20px" 
                  mr={iconIndex !== getBadgeIcons(user.flags).length - 1 ? 2 : 0}
                />
              ))}
            </Flex>
            <Text mb={4}>{renderStatus(user.status || 'offline')}</Text>
            {user.status.toLowerCase() !== 'offline' && (
              <Text mb={4}>
                {renderActivity(user.activityType, user.customStatus, user.activity, user.statusbody)}
              </Text>
            )}
          </Box>
        ))}
      </Flex>
    </Flex>
  );
};

export default AdminBotInfo;
