import React, { useState, useEffect, useCallback } from 'react';
import { Box, Heading, List, ListItem, Text, Flex, Avatar, Input, Button, Image, Link } from '@chakra-ui/react';
import axios from 'axios';

const TextChannelList = () => {
    const [channels, setChannels] = useState([]);
    const [selectedChannel, setSelectedChannel] = useState(null);
    const [users, setUsers] = useState([]);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [currentUser, setCurrentUser] = useState({ id: '123456', username: 'current_user' }); // Simulate the current user

    useEffect(() => {
        const fetchChannels = async () => {
            try {
                const response = await axios.get('https://adpanel.bot.mrapi.net/api/text'); // Update API to return message counts
                setChannels(response.data);
            } catch (error) {
                console.error('Error fetching text channels:', error);
            }
        };

        fetchChannels();
        const intervalId = setInterval(fetchChannels, 5000);

        return () => clearInterval(intervalId);
    }, []);

    const fetchMessagesAndUsers = useCallback(async (channelId) => {
        try {
            const messagesResponse = await axios.get(`https://adpanel.bot.mrapi.net/api/channel-messages?channelId=${channelId}`);
            setMessages(messagesResponse.data.messages || []);

            const usersResponse = await axios.get(`https://adpanel.bot.mrapi.net/api/channel-messages?channelId=${channelId}`);
            setUsers(usersResponse.data.users || []);
        } catch (error) {
            console.error('Error fetching messages or users for text channel:', error);
        }
    }, []);

    useEffect(() => {
        if (selectedChannel) {
            fetchMessagesAndUsers(selectedChannel);
            const intervalId = setInterval(() => fetchMessagesAndUsers(selectedChannel), 5000);

            return () => clearInterval(intervalId);
        }
    }, [selectedChannel, fetchMessagesAndUsers]);

    const handleChannelClick = (channelId) => {
        setSelectedChannel(channelId);
    };

    const handleSendMessage = async () => {
        if (newMessage.trim() === '') return;

        const newSentMessage = {
            id: Date.now(),
            content: newMessage,
            author: {
                id: currentUser.id,
                username: currentUser.username,
                avatar: '', 
            },
        };

        setMessages([...messages, newSentMessage]);
        setNewMessage('');
    };

    return (
        <Flex direction="row" h="calc(90vh - 80px)" p={4} mt="60px" bg="transparent" borderRadius="md" overflow="hidden">
            {/* Left side: Text Channels with a scrollable container */}
            <Box w="30%" p={4} bg="transparent" borderRadius="md" boxShadow="outline" overflowY="auto">
                <Heading as="h3" size="lg" mb={4}>Text Channels</Heading>
                <List spacing={3}>
                    {channels.map((channel) => (
                        <ListItem
                            key={channel.id}
                            p={2}
                            bg={selectedChannel === channel.id ? 'blue.700' : 'blue.300'}
                            _hover={{ bg: 'red.400', cursor: 'pointer' }}
                            onClick={() => handleChannelClick(channel.id)}
                        >
                            <Flex justify="space-between">
                                <Text fontWeight="bold">{channel.name}</Text>
                                <Text fontSize="sm" color="black">
                                    {channel.messagesCount} Messages {/* Display the number of messages */}
                                </Text>
                            </Flex>
                        </ListItem>
                    ))}
                </List>
            </Box>

            {/* Right side: Messages and Input */}
            <Box w="70%" p={4} ml={4} bg="transparent" borderRadius="md" boxShadow="outline" position="relative" display="flex" flexDirection="column" justifyContent="space-between">
                <Box>
                    <Heading as="h3" size="lg" mb={4}>
                        {selectedChannel ? `Messages in Channel [${channels.find(c => c.id === selectedChannel)?.name}]` : 'Select a Channel'}
                    </Heading>

                    {/* Messages Section */}
                    <List spacing={3} overflowY="scroll" maxH="600px" p={4} borderRadius="md" bg="transparent">
                        {messages.map((message) => (
                            <ListItem
                                key={message.id}
                                p={2}
                                mb={2}
                                borderRadius="md"
                                bg={message.author.id === currentUser.id ? 'blue.200' : 'transparent'}
                                alignSelf={message.author.id === currentUser.id ? 'flex-end' : 'flex-start'}
                                maxW="60%"
                                style={{ alignSelf: message.author.id === currentUser.id ? 'flex-end' : 'flex-start' }}
                            >
                                <Flex align="center">
                                    {message.author.id !== currentUser.id && (
                                        <Avatar size="sm" src={message.author.avatar} mr={3} />
                                    )}
                                    <Box>
                                        <Text fontWeight="bold">{message.author.username}</Text>
                                        <Text>{message.content}</Text>

                                        {/* Display attachments (like images or videos) */}
                                        {message.attachments && message.attachments.map(attachment => (
                                            <Box key={attachment.id} mt={2}>
                                                {attachment.contentType.startsWith('image/') ? (
                                                    <Image src={attachment.url} alt="attachment" maxW="100%" />
                                                ) : attachment.contentType.startsWith('video/') ? (
                                                    <video controls width="100%">
                                                        <source src={attachment.url} type={attachment.contentType} />
                                                    </video>
                                                ) : (
                                                    <Link href={attachment.url} isExternal>{attachment.url}</Link>
                                                )}
                                            </Box>
                                        ))}

                                        {/* Display embeds (like rich media content) */}
                                        {message.embeds && message.embeds.map((embed, index) => (
                                            <Box key={index} mt={2} p={2} border="1px solid" borderColor="gray.300" borderRadius="md">
                                                {embed.title && <Text fontWeight="bold">{embed.title}</Text>}
                                                {embed.description && <Text>{embed.description}</Text>}
                                                {embed.image && <Image src={embed.image} alt="embed" maxW="100%" />}
                                                {embed.video && (
                                                    <video controls width="100%">
                                                        <source src={embed.video} type="video/mp4" />
                                                    </video>
                                                )}
                                            </Box>
                                        ))}
                                    </Box>
                                </Flex>
                            </ListItem>
                        ))}
                    </List>
                </Box>

                {/* Message Input */}
                <Box mt={4} display="flex">
                    <Input
                        placeholder="Type a message..."
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                        mr={2}
                    />
                    <Button colorScheme="blue" onClick={handleSendMessage}>Send</Button>
                </Box>
            </Box>
        </Flex>
    );
};

export default TextChannelList;
