import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Box, Heading, List, ListItem, Text, Flex, Avatar, Button, IconButton, Tooltip } from '@chakra-ui/react';
import { useDisclosure, Checkbox } from '@chakra-ui/react';
import { Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/menu';
import { FaMicrophone, FaMicrophoneSlash, FaBan, FaVideo, FaHeadphones } from 'react-icons/fa';
import { TbHeadphonesOff } from "react-icons/tb";
import { CiStreamOn } from "react-icons/ci";
import { MdOutlineLiveTv } from "react-icons/md";
import axios from 'axios';
import UserMenu from './components/UserMenu';
import { FaCircle } from 'react-icons/fa';
import LogsRTCBot from './components/LogsRTCBot';

const VoiceChannelList = () => {
    const [channels, setChannels] = useState([]);
    const [selectedChannel, setSelectedChannel] = useState(null);
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [micMuted, setMicMuted] = useState(true);
    const [audioMuted, setAudioMuted] = useState(false);
    const [logs, setLogs] = useState('');
    const [showLogs, setShowLogs] = useState(false);
    const mediaStreamRef = useRef(null);
    const audioContextRef = useRef(null);
    const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
    const [menuOpen, setMenuOpen] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [botInfo, setBotInfo] = useState(() => {
        const savedBotInfo = localStorage.getItem('botInfo');
        return savedBotInfo ? JSON.parse(savedBotInfo) : { username: '', latency: 0 };
    });

    
    const playRadio = (streamUrl, radioName) => {
        axios.post('https://adpanel.bot.mrapi.net/api/play-radio', {
            channelId: selectedChannel,
            streamUrl,
            radioName // Envoie le nom de la radio
        })
        .then(() => {
            console.log(`Playing radio stream: ${streamUrl} in the selected channel!`);
        })
        .catch(error => {
            console.error('Error starting the radio stream:', error);
        });
    };
    

    useEffect(() => {
        const fetchChannels = async () => {
            try {
                const response = await axios.get('https://adpanel.bot.mrapi.net/api/voice');
                setChannels(response.data);
            } catch (error) {
                console.error('Error fetching channels:', error);
            }
        };

        fetchChannels();
        const intervalId = setInterval(fetchChannels, 5000);

        return () => clearInterval(intervalId);
    }, []);

    const fetchUsersAndBotInfo = useCallback(async (channelId) => {
        try {
            const [usersResponse, botInfoResponse] = await Promise.all([
                axios.get(`https://adpanel.bot.mrapi.net/api/channel-users?channelId=${channelId}`),
                axios.get('https://adpanel.bot.mrapi.net/api/!/secure/application/-/0/-/informations')
            ]);

            setUsers(usersResponse.data.users || []);
            setBotInfo((prevState) => ({
                ...prevState,
                latency: usersResponse.data.botPing || 0,
                username: botInfoResponse.data.username
            }));
        } catch (error) {
            console.error('Error fetching users and bot info:', error);
            setBotInfo((prevState) => ({ ...prevState, latency: 0 }));
        }
    }, []);

    useEffect(() => {
        if (selectedChannel) {
            fetchUsersAndBotInfo(selectedChannel);
            const intervalId = setInterval(() => fetchUsersAndBotInfo(selectedChannel), 5000);

            return () => clearInterval(intervalId);
        }
    }, [selectedChannel, fetchUsersAndBotInfo]);

    const handleChannelClick = (channelId) => {
        setSelectedChannel(channelId);
        axios.post(`https://adpanel.bot.mrapi.net/api/join`, { channelId })
            .then(() => console.log('Bot joined channel'))
            .catch((error) => console.error('Error joining channel:', error));
    };

    const handleDisconnect = () => {
        axios.post('https://adpanel.bot.mrapi.net/api/disconnect')
            .then(() => {
                console.log('Bot disconnected from voice channel');
                setBotInfo((prevState) => ({ ...prevState, latency: 0 }));
            })
            .catch((error) => console.error('Error disconnecting bot:', error));
    };

    const toggleMic = () => {
        setMicMuted((prev) => !prev);
    };

    const toggleAudio = () => {
        setAudioMuted((prev) => !prev);
    };



    const getStatusIcon = (status) => {
        if (status.toLowerCase() === 'dnd') {
            return (
                <div style={{ position: 'relative', display: 'inline-block' }}>
                    <FaCircle style={{ color: 'red', fontSize: '16px' }} />
                    <div style={{
                        position: 'absolute',
                        top: '50%',
                        left: '25%',
                        width: '50%',
                        height: '2px',
                        backgroundColor: 'black',
                        transform: 'translateY(-50%)',
                    }}></div>
                </div>
            );
        } else if (status.toLowerCase() === 'idle') {
            return (
                <div style={{ position: 'relative', display: 'inline-block' }}>
                    <FaCircle style={{ color: 'yellow', fontSize: '16px' }} />
                    <FaCircle style={{
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        color: 'white',
                        fontSize: '16px',
                        clipPath: 'circle(45% at 15% 10%)'  // Adjusted for less crescent and higher on the left
                    }} />
                </div>
            );
        } else if (status.toLowerCase() === 'online') {
            return <FaCircle style={{ color: 'green', fontSize: '16px' }} />;
        } else if (status.toLowerCase() === 'offline') {
            return (
                <div style={{ position: 'relative', display: 'inline-block' }}>
                    <FaCircle style={{ color: 'gray', fontSize: '16px' }} />
                    <FaCircle style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        width: '8px',  // Smaller circle inside
                        height: '8px',
                        borderRadius: '50%',
                        backgroundColor: 'white',  // Transparent or white center for "offline"
                        transform: 'translate(-50%, -50%)',
                    }} />
                </div>
            );
        } else {
            return <FaCircle style={{ color: getStatusColor(status), fontSize: '16px' }} />;
        }
    };

    const handleUserClick = (event, user) => {
        if (!user) {
            console.error('User is undefined or null:', user);
            return;
        }
        // Adjust for page scroll and container offset
        const xOffset = window.pageXOffset || document.documentElement.scrollLeft;
        const yOffset = window.pageYOffset || document.documentElement.scrollTop;

        setSelectedUser(user);
        setMenuPosition({
            x: event.clientX + xOffset,  // Add scroll offset to X
            y: event.clientY + yOffset   // Add scroll offset to Y
        });
        setMenuOpen(true);  // Manually open the menu
        console.log(`Menu open for user: ${user.username} at position: `, { x: event.clientX + xOffset, y: event.clientY + yOffset });
    };

    return (
        <Flex direction="row" h="calc(90vh - 80px)" p={4} mt="60px" bg="transparent" borderColor="purple.300" borderRadius="md" overflow="hidden">
            <Box w="30%" p={4} bg="transparent" borderRadius="md" boxShadow="outline" borderColor="purple.300">
                <Heading as="h3" size="lg" mb={4}>Channels Vocal</Heading>
                <List spacing={3}>
                    {channels.map((channel) => (
                        <ListItem
                            key={channel.id}
                            p={2}
                            bg={selectedChannel === channel.id ? 'blue.700' : 'blue.300'}
                            _hover={{ bg: 'red.400', cursor: 'pointer' }}
                            onClick={() => handleChannelClick(channel.id)}
                        >
                            <Flex justify="space-between">
                                <Text fontWeight="bold">{channel.name}</Text>
                                <Text fontSize="sm" color="back.900">
                                    {channel.users ? channel.users.length : 0} Users
                                </Text>
                            </Flex>
                        </ListItem>
                    ))}
                </List>
            </Box>

            <Box w="70%" p={4} ml={4} bg="transparent"  borderColor="purple.300" borderRadius="md" boxShadow="outline" position="relative">
                <Heading as="h3" size="lg" mb={4}>
                    {selectedChannel ? `Utilisateurs connecté dans le Channel [${channels.find(c => c.id === selectedChannel)?.name}]` : 'Select a Channel'}
                </Heading>
                <Text mb={4} color="blue.500">
                    {botInfo.username ? `${botInfo.username}` : '-'} Discord Latency: {botInfo.latency} ms
                </Text>

                <List spacing={3}>
                    {users.map(user => (
                        <ListItem
                            key={user.id}
                            p={2}
                            bg="gray.50"
                            _hover={{ bg: 'blue.100', cursor: 'pointer' }}
                            onClick={(e) => handleUserClick(e, user)}
                        >
                            <Flex align="center" justify="space-between">
                                <Flex align="center">
                                    <Avatar size="sm" src={user.avatar} mr={3} />
                                    <Text fontWeight="medium" color="black">{user.username}</Text>
                                </Flex>
                                <Flex align="center">
                                    <Flex align="center" mr={2}>
                                        {getStatusIcon(user.status)}
                                    </Flex>
                                    {user.mute && (
                                        <Flex align="center" mr={2}>
                                            <FaMicrophoneSlash color="red" />
                                        </Flex>
                                    )}
                                    {user.deaf && (
                                        <Flex align="center" mr={2}>
                                            <TbHeadphonesOff color="red" />
                                        </Flex>
                                    )}
                                    {user.self_stream && (
                                        <Flex align="center" mr={2}>
                                            <MdOutlineLiveTv color="purple" />
                                        </Flex>
                                    )}
                                    {user.self_video && (
                                        <Flex align="center" mr={2}>
                                            <FaVideo color="green" />
                                        </Flex>
                                    )}
                                    {user.suppress && (
                                        <Flex align="center" mr={2}>
                                            <FaBan color="orange" />
                                        </Flex>
                                    )}
                                </Flex>
                            </Flex>
                        </ListItem>
                    ))}
                </List>

                <Flex mt={4} align="center">
                    <IconButton
                        icon={micMuted ? <FaMicrophoneSlash /> : <FaMicrophone />}
                        aria-label="Toggle Microphone"
                        onClick={toggleMic}
                        mr={2}
                    />
                    <IconButton
                        icon={audioMuted ? <FaHeadphones /> : <TbHeadphonesOff />}
                        aria-label="Toggle Audio"
                        onClick={toggleAudio}
                        mr={2}
                    />
                </Flex>

                <Button mt={4} colorScheme="purple" onClick={() => playRadio('https://n43a-eu.rcs.revma.com/7081n2b6p98uv?rj-ttl=5&rj-tok=AAABkk9Vi-4A4wXn_C5FnXroLg', 'FG.')}>FG.</Button>
                <Button mt={4} colorScheme="purple" onClick={() => playRadio('https://25223.live.streamtheworld.com/Q_DANCE.mp3', 'Q-Dance Radio')}>Q-Dance Radio</Button>
                <Button mt={4} colorScheme="purple" onClick={() => playRadio('https://22323.live.streamtheworld.com/WEB11_AAC.aac', 'SLAM! HardStyle')}>SLAM! HardStyle</Button>

                <Button mt={4} colorScheme="red" onClick={handleDisconnect}>Disconnect</Button>
                <Button mt={4} onClick={() => setShowLogs(!showLogs)}>
                    {showLogs ? 'Close Debug Console' : 'Open Debug Console'}
                </Button>

                {showLogs && <LogsRTCBot />}
                {selectedUser && (
                    <UserMenu
                        user={selectedUser}
                        onAction={(action) => console.log(`User action: ${action}`)}
                    />
                )}
            </Box>
        </Flex>
    );
};

export default VoiceChannelList;
