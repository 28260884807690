import React, { useEffect } from 'react';
import Terminal from 'terminal-in-react';
import { Box, Flex } from '@chakra-ui/react';
import './Terminal.css';

const MyTerminal = () => {
  const customCommands = {
    hello: () => 'Hello, world!',
    showDate: () => new Date().toLocaleString(),
    alert: (args) => alert(args),
    showlogs: () => 'Here are your logs...', // Example custom command
  };

  useEffect(() => {
    const applyInlineStyles = () => {
      const hdqhPL = document.querySelector('.sc-bxivhb.hdqhPL');
      const lfnIny = document.querySelector('.sc-jTzLTM.lfnIny');
      const dMFuoo = document.querySelector('.sc-EHOje.dMFuoo');
      const terminalBase = document.querySelector('.terminal-base.sc-bdVaJa.blCJWW');

      if (hdqhPL) hdqhPL.style.maxWidth = '100%';
      if (lfnIny) {
        lfnIny.style.maxWidth = '100%';
        lfnIny.style.background = "#000";
        lfnIny.style.height = '100%';
      }
      if (dMFuoo) {
        dMFuoo.style.overflowY = 'auto';
        dMFuoo.style.maxWidth = '100%';
        dMFuoo.style.maxHeight = 'calc(100% - 60px)';
      }
      if (terminalBase) {
        terminalBase.style.maxWidth = '100%';
      }
    };

    applyInlineStyles();
  }, []);

  return (
    <Flex direction="row" h="calc(90vh - 80px)" justify="center" p={4} mt="60px" bg="transparent" borderRadius="md" overflow="hidden">
      <Box flexGrow={1} id="terminal-container" p={4} h="100%" w="100%">
        <Terminal
          color="green"
          backgroundColor="black"
          barColor="black"
          style={{
            fontWeight: 'bold',
            fontSize: '1em',
            height: '100%',
            width: '100%',
          }}
          commands={customCommands}
          actionHandlers={{
            handleInput: () => false, // Terminal in read-only mode
          }}
          promptSymbol=""
          hidePromptWhenDisabled={true}
          msg="Terminal Server Ready! Type 'showlogs' to view logs."
        />
      </Box>
    </Flex>
  );
};

export default MyTerminal;
