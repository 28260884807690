import React, { useState, useEffect } from 'react';
import { Box, Text, Center } from '@chakra-ui/react';

const DraggableRadioClock = ({
  color = 'red.500',
  bgColor = 'black',
  fontSize = '6xl',
  circleColor = 'red.500',
  circleSize = 60, 
}) => {
  const [time, setTime] = useState(new Date());
  const [position, setPosition] = useState({ x: 20, y: 120 });
  const [isDragging, setIsDragging] = useState(false);
  const [offset, setOffset] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(new Date());
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setOffset({
      x: e.clientX - position.x,
      y: e.clientY - position.y,
    });
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      setPosition({
        x: e.clientX - offset.x,
        y: e.clientY - offset.y,
      });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging, offset]);

  const hours = time.getHours().toString().padStart(2, '0');
  const minutes = time.getMinutes().toString().padStart(2, '0');
  const seconds = time.getSeconds();
  const angleIncrement = (2 * Math.PI) / circleSize;
  const radius = 90;

  const points = Array.from({ length: circleSize }, (_, i) => {
    const angle = i * angleIncrement - Math.PI / 2;
    const x = radius * Math.cos(angle);
    const y = radius * Math.sin(angle);
    const isActive = i < seconds;
    return (
      <Box
        key={i}
        position="absolute"
        top={`calc(50% + ${y}px)`}
        left={`calc(50% + ${x}px)`}
        transform="translate(-50%, -50%)"
        borderRadius="50%"
        width="8px"
        height="8px"
        bg={isActive ? circleColor : 'gray.700'}
        
      />
    );
  });

  return (
    <Box
      position="absolute"
      top={`${position.y}px`}
      left={`${position.x}px`}
      cursor="grab"
      onMouseDown={handleMouseDown}
      zIndex="1000"
    >
      <Box
        position="relative"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        bg={bgColor}
        color={color}
        padding="8"
        borderRadius="full"
        width="200px"
        height="200px"
        boxShadow="dark-lg"
      >
        {/* Cercle de points autour de l'horloge */}
        {points}

        {/* Heure */}
        <Text fontSize={fontSize} fontFamily="monospace" color={color}>
          {hours}:{minutes}
        </Text>
      </Box>
    </Box>
  );
};

export default DraggableRadioClock;
